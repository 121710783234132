import qs from "qs";
import axios from "axios";

const fetchTweetsByTagEndpoint = `/api/v1/tweet/tag`;
const fetchTagCountsEndpoint = `/api/v1/tweet/tag/counts`;
const fetchAllTags = `/api/v1/tweet/tag/all`;
const fetchTagsByPopularity = `/api/v1/tweet/tag/popularity`;
const fetchUsersByTag = `/api/v1/tweet/tag/users`;
const fetchTagTrend = `/api/v1/tweet/tag/trends`;

const createHeadersWithToken = async getTokenSilently => {
  const token = await getTokenSilently();
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
};

const callTagTrendApi = async (getTokenSilently, tags, days = 7) => {
  try {
    const config = await createHeadersWithToken(getTokenSilently);
    const response = await axios.get(`${fetchTagTrend}?${qs.stringify({ tags, days })}`, config);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const callTweetsByTagApi = async (getTokenSilently, tag, days, page, search = '') => {
  try {
    const config = await createHeadersWithToken(getTokenSilently);
    const response = await axios.get(`${fetchTweetsByTagEndpoint}?${qs.stringify({ tag, days, page, search })}`, config);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const callTagCountApi = async (getTokenSilently, tag, days, search = '') => {
  try {
    const config = await createHeadersWithToken(getTokenSilently);
    const response = await axios.get(`${fetchTagCountsEndpoint}?${qs.stringify({ tag, days, search })}`, config);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const callAllTagsApi = async (getTokenSilently, days) => {
  try {
    const config = await createHeadersWithToken(getTokenSilently);
    const response = await axios.get(`${fetchAllTags}?${qs.stringify({ days })}`, config);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const callTagsByPopularityApi = async (days, limit) => {
  try {
    const config = await createHeadersWithToken();
    const response = await axios.get(`${fetchTagsByPopularity}/${qs.stringify({ days, limit })}`, config);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const callUserByTagApi = async (getTokenSilently, tag, days) => {
  try {
    const config = await createHeadersWithToken(getTokenSilently);
    const response = await axios.get(`${fetchUsersByTag}?${qs.stringify({ tag, days })}`, config);
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export {
  callTagTrendApi,
  callTweetsByTagApi,
  callTagCountApi,
  callAllTagsApi,
  callTagsByPopularityApi,
  callUserByTagApi
};