import React, {useState} from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import moment from "moment";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import {DialogContent, DialogContentText} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import TweetCard from "./TweetCard";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    height: 750,
    overflow: "auto",
    padding: 5
  }
}));

const TagUserList = ({ detailsUsers, selectedDetailsTag }) => {
  const classes = useStyles();
  const [viewTweet, setViewTweet] = useState(null);
  const [open, setOpen] = useState(false);

  const setSelected = tweet => {
    setViewTweet(tweet);
    setOpen (true);
  }

  return (
    <>
      <List className={classes.root}>
        <Typography variant="h6">
          Who's talking about #{selectedDetailsTag}?
        </Typography>
        {detailsUsers && detailsUsers.map(tweet => (
          <ListItem onClick={() => setSelected(tweet)}>
            <ListItemAvatar>
              <Avatar aria-label="user" src={tweet.profile_image_url} />
            </ListItemAvatar>
            <ListItemText
              primary={`@${tweet.screen_name}`}
              secondary={moment(tweet.created_at).format(
                "MMMM Do, YYYY,  h:mm:ss a"
              )}
            />
          </ListItem>
        ))}
      </List>
      {open && (
        <Dialog onClose={() => setOpen(false)} aria-labelledby="simple-dialog-title" open={open}>
          <DialogContent>
            <DialogContentText>
              <TweetCard tweet={viewTweet} />
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

TagUserList.propTypes = {
  detailsUsers: PropTypes.array.isRequired,
  selectedDetailsTag: PropTypes.string.isRequired
};

export default TagUserList;
