import React from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  margin: { marginTop: 15 }
}));

const TweetFilterSearch = ({ search, handleSetSearch }) => {
  const classes = useStyles();
  return (
    <FormControl fullWidth className={classes.margin}>
      <InputLabel htmlFor="search">Search</InputLabel>
      <Input id="search" value={search} onChange={handleSetSearch} />
    </FormControl>
  );
};

TweetFilterSearch.propTypes = {
  search: PropTypes.string,
  handleSetSearch: PropTypes.func.isRequired
};

export default TweetFilterSearch;
