import React from "react";
import PropTypes from "prop-types";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  daySelect: {
    marginBottom: 20
  }
}));

const TweetFiltersDaySelect = props => {
  const { days, handleChangeDays } = props;
  const classes = useStyles();

  return (
    <FormControl fullWidth className={classes.daySelect}>
      <InputLabel htmlFor="age-native-simple">Time Period</InputLabel>
      <Select
        native
        value={days}
        onChange={handleChangeDays}
        inputProps={{
          name: "days",
          id: "days-select"
        }}
      >
        <option value={1}>Last Day</option>
        <option value={7}>Last Week</option>
        <option value={30}>Last 30 Days</option>
        <option value={365}>Last Year</option>
      </Select>
    </FormControl>
  );
};

export default TweetFiltersDaySelect;

TweetFiltersDaySelect.propTypes = {
  days: PropTypes.number.isRequired,
  handleChangeDays: PropTypes.func.isRequired
};
