import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer
} from "recharts";

const COLORS = ["red", "blue", "green", "yellow", "purple", "orange", "pink"];

function getColor(index) {
  if (index < 6) return COLORS[index];
  if (index % 1 === 0) return COLORS[0];
  if (index % 2 === 0) return COLORS[1];
  if (index % 3 === 0) return COLORS[2];
  if (index % 4 === 0) return COLORS[3];
  if (index % 5 === 0) return COLORS[4];
  if (index % 6 === 0) return COLORS[5];
  if (index % 7 === 0) return COLORS[6];

  return COLORS[0];
}

const TrendingGraph = ({ selectedTag, trendData }) => {
  return (
    <>
      <Typography variant="h6">What's trending?</Typography>
      <ResponsiveContainer width="90%" height={350}>
        <LineChart
          data={trendData}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          {selectedTag &&
            selectedTag
              .split(",")
              .map((tag, index) => (
                <Line type="monotone" dataKey={tag} stroke={getColor(index)} />
              ))}
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

TrendingGraph.propTypes = {
  trendData: PropTypes.array.isRequired,
  selectedTag: PropTypes.object.isRequired
};

export default TrendingGraph;
