import React from "react";
import NavBar from "./components/NavBar";
import { useAuth0 } from "./react-auth0-wrapper";
import { BrowserRouter, Switch } from "react-router-dom";
import Home from "./components/Home";
import PrivateRoute from "./components/PrivateRoute";

const App = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  if (isAuthenticated === false) {
    loginWithRedirect({});
    return <></>;
  }

  return (
    <div className="App">
      <BrowserRouter>
        <header>
          <NavBar />
        </header>
        <Switch>
          <PrivateRoute path="/" component={Home} exact />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
