import React from "react";
import { emphasize, makeStyles, useTheme } from "@material-ui/core/styles";
import WindowedSelect from "react-windowed-select";

import {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
} from "../TypeAheadMenu";
const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
};

const useStyles = makeStyles(theme => ({
  input: {
    display: "flex",
    padding: 0,
    height: "auto"
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden"
  },
  chip: {
    margin: theme.spacing(0.5, 0.25)
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2)
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: "absolute",
    left: 2,
    bottom: 6,
    fontSize: 16
  },
  paper: {
    position: "absolute",
    zIndex: 9999999,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing(2)
  }
}));

const TypeAheadSelect = props => {
  const {
    selected,
    label,
    inputId,
    suggestions,
    handleChange,
    placeholder,
    isMulti = false
  } = props;
  const classes = useStyles();
  const theme = useTheme();

  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      "& input": {
        font: "inherit"
      }
    })
  };

  return (
    <WindowedSelect
      classes={classes}
      styles={selectStyles}
      inputId={inputId}
      TextFieldProps={{
        label,
        InputLabelProps: {
          htmlFor: label,
          shrink: true
        }
      }}
      placeholder={placeholder}
      options={suggestions}
      components={components}
      value={selected}
      onChange={handleChange}
      isMulti={isMulti}
    />
  );
};

export default TypeAheadSelect;
