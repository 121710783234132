import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import FavoriteIcon from "@material-ui/icons/Favorite";
import RepeatIcon from "@material-ui/icons/Repeat";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import CardMedia from "@material-ui/core/CardMedia";

const useStyles = makeStyles(theme => ({
  card: {
    marginTop: 1,
    marginBottom: 1
    // width: "100%"
  },
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  },
  count: {
    marginLeft: 10
  },
  hashes: {
    marginTop: 10
  }
}));

const TweetCard = ({ tweet }) => {
  const classes = useStyles();
  console.log("tweet", tweet);
  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={
          <Avatar
            aria-label="user"
            src={
              tweet.user
                ? tweet.user.profile_image_url
                : tweet.profile_image_url
            }
          />
        }
        title={tweet.user ? tweet.user.screen_name : tweet.screen_name}
        subheader={moment(tweet.created_at).format("MMMM Do, YYYY,  h:mm:ss a")}
      />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {tweet.text ? tweet.text : tweet.tweet_text}
        </Typography>
        <div className={classes.hashes}>
          {tweet.entities &&
            tweet.entities.hashtags &&
            tweet.entities.hashtags.map(hash => `#${hash.text}`).join(", ")}
        </div>
      </CardContent>
      {tweet.entities && tweet.entities.media && tweet.entities.media[0] && (
        <CardMedia
          className={classes.media}
          image={tweet.entities.media[0].media_url}
        />
      )}
      <CardActions disableSpacing>
        <IconButton>
          <FavoriteIcon fontSize="small" />{" "}
          <span className={classes.count}>{tweet.favorite_count}</span>
        </IconButton>
        <IconButton>
          <RepeatIcon fontSize="small" />{" "}
          <span className={classes.count}>{tweet.retweet_count}</span>
        </IconButton>
      </CardActions>
    </Card>
  );
};

export default TweetCard;
