import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {
  callTweetsByTagApi,
  callAllTagsApi,
  callTagCountApi,
  callUserByTagApi,
  callTagTrendApi
} from "../api";
import TweetCard from "./TweetCard";
import Typography from "@material-ui/core/Typography";
import TweetStats from "./TweetStats";
import TweetFilters from "./TweetFilters";
import TagUserList from "./TagUserList";
import TrendingGraph from "./TrendingGraph";
import Loader from "./common/Loader";

const useStyles = makeStyles(theme => ({
  root: { marginTop: 30 },
  filters: { marginRight: 20 }
}));

const Home = ({ getTokenSilently }) => {
  const classes = useStyles();
  const [tweets, setTweets] = useState(null);
  const [tags, setTags] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedTag, setSelectedTag] = useState(null);
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [days, setDays] = useState(1);
  const [counts, setCounts] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [allowScroll, setAllowScroll] = useState(true);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [selectedDetailsTag, setSelectedDetailsTag] = useState(null);
  const [detailsUsers, setDetailsUsers] = useState(null);
  const [trendData, setTrendData] = useState(null);

  const handleSelectDetailTag = name => setSelectedDetailsTag(name);

  const clearTweets = () => {
    setPage(0);
    setHasMore(true);
    setTweets(null);
    setSelectedDetailsTag(null);
  };
  const handleSetSearch = event => {
    setSearch(event.target.value);
    clearTweets();
  };
  const handleSelectTag = async tag => {
    const selectedValues = tag ? tag.map(t => t.value) : null;
    setSelectedTag(selectedValues ? selectedValues.join(",") : null);
    setSelectedSuggestion(tag);
    clearTweets();
  };
  const handleChangeDays = event => {
    setDays(event.target.value);
    clearTweets();
  };

  useEffect(() => {
    const fetchTags = async () => {
      const data = await callAllTagsApi(getTokenSilently, days);
      setTags(data);
      setSuggestions(
        data
          ? data.map(tag => ({
              label: tag,
              value: tag
            }))
          : []
      );
    };

    fetchTags();
  }, [getTokenSilently, days]);

  useEffect(() => {
    const loadCounts = async () => {
      if (!selectedTag) {
        setCounts(null);
        return;
      }
      const data = await callTagCountApi(
        getTokenSilently,
        selectedTag,
        days,
        search
      );
      setCounts(data);
    };

    loadCounts();
  }, [getTokenSilently, selectedTag, days, search]);

  useEffect(() => {
    const loadUsersByDetail = async () => {
      if (!selectedDetailsTag) return;
      const data = await callUserByTagApi(
        getTokenSilently,
        selectedDetailsTag,
        days
      );
      setDetailsUsers(data);
    };

    loadUsersByDetail();
  }, [getTokenSilently, days, selectedDetailsTag]);

  useEffect(() => {
    const loadTrending = async () => {
      if (!selectedTag) return;
      const data = await callTagTrendApi(getTokenSilently, selectedTag, 7);
      console.log("trending", data);
      setTrendData(data);
    };

    loadTrending();
  }, [getTokenSilently, selectedTag]);

  const loadFunc = async () => {
    if (!selectedTag || !allowScroll) return;

    setAllowScroll(false);
    const data = await callTweetsByTagApi(
      getTokenSilently,
      selectedTag,
      days,
      page,
      search
    );
    setTweets(tweets ? [...tweets, ...data] : data);
    setPage(page + 1);

    if (data && !data.length) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }

    setTimeout(() => {
      setAllowScroll(true);
    }, 1000);
  };

  const items =
    tweets &&
    tweets.map((tweet, index) => <TweetCard key={index} tweet={tweet} />);

  return (
    <Grid container className={classes.root}>
      {tags ? (
        <>
          <Grid item xs={1} />
          <Grid item xs={2} className={classes.filters}>
            <TweetFilters
              days={days}
              handleChangeDays={handleChangeDays}
              handleSelectTag={handleSelectTag}
              selectedSuggestion={selectedSuggestion}
              suggestions={suggestions}
              search={search}
              handleSetSearch={handleSetSearch}
            />
          </Grid>
          <Grid item xs={5}>
            {counts && (
              <TweetStats
                days={days}
                counts={counts}
                handleSelectDetailTag={handleSelectDetailTag}
              />
            )}
            {selectedTag && trendData && (
              <TrendingGraph selectedTag={selectedTag} trendData={trendData} />
            )}
            {!selectedTag && (
              <Typography variant="h6">
                Select a tag to view associated analytics...
              </Typography>
            )}
          </Grid>
          <Grid item xs={2}>
            {selectedDetailsTag && (
              <TagUserList
                detailsUsers={detailsUsers}
                selectedDetailsTag={selectedDetailsTag}
              />
            )}
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <Loader />
        </Grid>
      )}
    </Grid>
  );
};

Home.propTypes = {
  getTokenSilently: PropTypes.func.isRequired
};

export default Home;
