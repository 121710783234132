import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth0 } from "../react-auth0-wrapper";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TwitterIcon from '@material-ui/icons/Twitter';


const useStyles = makeStyles(theme => ({
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  }
}));

const NavBar = () => {
  const classes = useStyles();
  const { isAuthenticated, logout } = useAuth0();

  return (
    <AppBar position="static">
       <Toolbar>
         <Typography variant="h6" className={classes.title}>
           <strong>IN10T </strong>  <TwitterIcon fontSize="small" />
         </Typography>
         {!isAuthenticated ? (
           <></>
         ) : (
           <Button onClick={logout} color="inherit">
             Logout
           </Button>
         )}
       </Toolbar>
    </AppBar>
  );
};

export default NavBar;