import React from "react";
import PropTypes from "prop-types";
import TypeAheadSelect from "../common/TypeAheadSelect";

const TweetFiltersTagSelect = props => {
  const { selectedSuggestion, suggestions, handleSelectTag } = props;

  return (
    <TypeAheadSelect
      selected={selectedSuggestion}
      label="Tags"
      inputId="tag-select"
      suggestions={suggestions}
      handleChange={handleSelectTag}
      placeholder="Select a Tag"
      isMulti={true}
    />
  );
};

export default TweetFiltersTagSelect;

TweetFiltersTagSelect.propTypes = {
  selectedSuggestion: PropTypes.object,
  suggestions: PropTypes.array,
  handleSelectTag: PropTypes.func.isRequired
};
