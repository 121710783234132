import React from "react";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core";
import TweetFiltersDaySelect from "./TweetFiltersDaySelect";
import TweetFiltersTagSelect from "./TweetFiltersTagSelect";
import TweetFilterSearch from "./TweetFilterSearch";

const useStyles = makeStyles(theme => ({
  filterPaper: { height: 750 }
}));

const TweetFilters = ({
  days,
  handleChangeDays,
  selectedSuggestion,
  suggestions,
  handleSelectTag,
  search,
  handleSetSearch
}) => {
  const classes = useStyles();
  return (
    <Paper>
      <Card className={classes.filterPaper}>
        <CardContent>
          <Typography variant="h6">Filters</Typography>
          <TweetFiltersDaySelect
            days={days}
            handleChangeDays={handleChangeDays}
          />
          <TweetFiltersTagSelect
            handleSelectTag={handleSelectTag}
            selectedSuggestion={selectedSuggestion}
            suggestions={suggestions}
          />
          {/*<TweetFilterSearch*/}
          {/*  search={search}*/}
          {/*  handleSetSearch={handleSetSearch}*/}
          {/*/>*/}
        </CardContent>
      </Card>
    </Paper>
  );
};

TweetFilters.propTypes = {
  days: PropTypes.number.isRequired,
  handleChangeDays: PropTypes.func.isRequired,
  selectedSuggestion: PropTypes.object,
  suggestions: PropTypes.array,
  handleSelectTag: PropTypes.func.isRequired,
  search: PropTypes.string,
  handleSetSearch: PropTypes.func.isRequired
};

export default TweetFilters;
