import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import {
  Tooltip,
  Treemap,
  ResponsiveContainer
} from "recharts";
import DAYS_LABEL from "../constants/daysLabel";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  chart: {
    padding: 10
  }
}));

const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div
        className="custom-tooltip"
        style={{ backgroundColor: "black", padding: 10 }}
      >
        <p className="label">{`${payload[0].payload &&
          payload[0].payload.name} : ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

const TweetStats = ({ counts, days, handleSelectDetailTag }) => {
  const classes = useStyles();

  return (
    <div className={classes.chart}>
      <Typography variant="h6">Mention Count ({DAYS_LABEL[days]})</Typography>
      <ResponsiveContainer width="95%" height={350}>
        <Treemap
          data={counts}
          dataKey="count"
          ratio={4 / 3}
          stroke="#fff"
          fill="gray"
          isAnimationActive={true}
          onClick={val => handleSelectDetailTag(val.name)}
        >
          <Tooltip content={<CustomTooltip />} />
        </Treemap>
      </ResponsiveContainer>
    </div>
  );
};

export default TweetStats;

TweetStats.propTypes = {
  days: PropTypes.number.isRequired,
  counts: PropTypes.array.isRequired,
  handleSelectDetailTag: PropTypes.func.isRequired
};
